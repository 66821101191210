exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-asdasd-tsx": () => import("./../../../src/pages/asdasd.tsx" /* webpackChunkName: "component---src-pages-asdasd-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-collection-item-tsx": () => import("./../../../src/templates/collection-item.tsx" /* webpackChunkName: "component---src-templates-collection-item-tsx" */),
  "component---src-templates-collections-page-tsx": () => import("./../../../src/templates/collections-page.tsx" /* webpackChunkName: "component---src-templates-collections-page-tsx" */),
  "component---src-templates-custom-work-page-tsx": () => import("./../../../src/templates/custom-work-page.tsx" /* webpackChunkName: "component---src-templates-custom-work-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-jewellery-care-page-tsx": () => import("./../../../src/templates/jewellery-care-page.tsx" /* webpackChunkName: "component---src-templates-jewellery-care-page-tsx" */),
  "component---src-templates-policy-page-tsx": () => import("./../../../src/templates/policy-page.tsx" /* webpackChunkName: "component---src-templates-policy-page-tsx" */),
  "component---src-templates-shop-item-tsx": () => import("./../../../src/templates/shop-item.tsx" /* webpackChunkName: "component---src-templates-shop-item-tsx" */),
  "component---src-templates-shop-page-tsx": () => import("./../../../src/templates/shop-page.tsx" /* webpackChunkName: "component---src-templates-shop-page-tsx" */)
}

